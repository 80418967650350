.racPinField {
    height: 45px !important;
    width: 45px !important;
    margin-right: 1rem !important;
    margin-left: 0.5rem;
    text-align: center;
    border-radius: 0.6rem;
    border: 1px solid #adb5bd;
    font-size: x-large !important;
}
.racPinField:focus
{
    outline: none !important;
}
.racPinContainer {
    margin: 0 auto;
    width: 94% !important;
}
.racPinLabel
{
    font-family: 'OpenSans-semibold' !important;
    color: #000 !important;
    margin-left: 0.5rem !important;
    margin-bottom: 3px !important;
}
.racPinError
{
    color: red;
    margin-top: 5px !important;
    margin-left: 0.5rem !important;
    font-family: 'OpenSans-semibold' !important;
}
.racErrorFunction
{
    font-family: 'OpenSans-semibold' !important;
    margin-bottom: 20px !important;
    margin-top: 10px !important;
}
.racErrorIcon
{
    width: 50px;
}
.racLoginAlert
{
    color: red;
    font-family: 'OpenSans-semibold' !important;
    font-size: 14px !important;
}
.text-center
{
    text-align: center !important;
}
.racPinPopup .RAC-DASHBOARD--MuiDialog-container .RAC-DASHBOARD--MuiDialog-paperWidthXs {
    max-width: 340px !important;
}
.racSecondFacPopup .RAC-DASHBOARD--MuiDialog-container .RAC-DASHBOARD--MuiDialog-paperWidthXs {
    max-width: 340px !important;
}
.MuiTypography-h5
{
    font-family: 'OpenSans-bold' !important;
    font-size: 18px !important;
}
.MuiDialogContent-root {
    padding: 1.5rem !important;
}
.MuiDialog-paper {
    border-radius: 20px !important;
}
.racSearchPrimary:disabled, .racSearchPrimary.disabled {
    color: #fff;
    background-color: #0d6efd;
    border-color: #0d6efd;
}
.racSearch:disabled, .racSearch.disabled, fieldset:disabled .racSearch {
    pointer-events: none;
    opacity: 0.65;
}

.racSearchPrimary {
    color: #fff !important;
    background-color: #0d6efd !important;
    border-color: #0d6efd !important;
    padding: 10px 22px!important;
    background-color: #2179FE !important;
    font-family: 'OpenSans-semibold'!important;
    font-size: 14px!important;
}
.racSearch {
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

/* .MuiFormControl-root{
    width:150% !important;
} */
/* .MuiPaper-root{
      border-radius: 25px !important;
} */
.btn-secondary {
    color: #5a5a5a !important;
    background-color: #fafcff !important;
    border-color: #cee0ff !important;
    padding: 10px 22px!important;
    font-family: 'OpenSans-semibold'!important;
    font-size: 14px!important;
    outline: auto !important;
    outline-color: #cee0ff !important;
}

.btn-secondary:focus {
    color: #fff !important;
    background-color: #5c636a !important;
    border-color: #565e64 !important;
    box-shadow: 0 0 0 0.25rem rgba(130, 138, 145, 0.5) !important;
}
.btn-secondary:disabled, .btn-secondary.disabled {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
}
.racSecFactorTextbox
{
    width: 100% !important;
}
.racLoader
{
  position: absolute !important;
  top: 45% !important;
  left: 50% !important;
  transform: translate(-50%, 85%) !important;
}
  